.projects-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding-bottom: 50px;
}

/* Hero Section */
.projects-hero-container {
  background-image: url('/public/images/DSC04770\ 1.jpeg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projects-intro-container {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 50px;
  border-radius: 15px;
  max-width: 900px;
  text-align: center;
  color: #ffffff;
}

.projects-hero-title {
  font-size: 3.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
}

/* Projects Container */
.projects-container {
  margin-top: 40px; /* Adds the margin below the hero image */
}

/* Projects Grid */
.projects-grid {
  margin-top: 20px;
}

/* Project Card */
.project-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.project-content {
  flex: 0.6;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-title {
  font-size: 2.5rem;
  color: #2c3e50;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.project-description {
  font-size: 6rem; /* Increased text size */
  color: #444444; /* Darker color for better readability */
  line-height: 2;
  text-align: left;
  font-style: italic; /* Added a fancy style */
}

.project-image-container {
  flex: 0.4;
  position: relative;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  filter: grayscale(50%);
  transition: filter 0.3s ease;
  border-radius: 0 15px 15px 0;
}

/* Hover Effects */
.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-card:hover .project-image {
  filter: grayscale(0%);
}

/* Gallery Container */
.gallery-container {
  margin-top: 40px;
}

.gallery-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5rem;
  color: #2c3e50;
  font-weight: bold;
}

.gallery-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 15px;
}

.gallery-image-container:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .project-card {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .project-image-container {
    order: -1;
    width: 100%;
    height: auto;
  }

  .project-image {
    border-radius: 15px 15px 0 0;
  }

  .project-content {
    padding: 20px;
  }

  .project-title {
    font-size: 2rem;
  }

  .project-description {
    font-size: 1.1rem; /* Adjusted text size for smaller screens */
  }

  .projects-hero-title {
    font-size: 2.5rem;
  }
}
