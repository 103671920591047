/* src/App.css */
@media screen and (max-width: 768px) {
  .container {
    width: 100% !important;
    max-width: none !important;
  }

  body,
  header {
    overflow-x: hidden !important;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.App {
  transition: background-color 0.3s, color 0.3s;
}

.App.light {
  background-color: #ffffff;
  color: #000000;
}

.App.dark {
  background-color: #121212;
  color: #ffffff;
}

/* Add other styles here */
