.contact-wrapper {
  padding-bottom: 50px;
}

.contact-hero-container {
  background-image: url('/public/images/cds.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-intro-container {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 50px;
  border-radius: 15px;
  max-width: 900px;
  text-align: center;
  color: #ffffff;
}

.contact-hero-title {
  font-size: 3.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #ffffff;
}

.contact-hero-subtitle {
  font-size: 1.5rem;
  line-height: 1.8;
}

.giving-options-container {
  margin-top: 40px;
}

.giving-options-grid {
  margin-top: 20px;
}

.giving-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.giving-content {
  padding: 30px;
}

.giving-card-title {
  font-size: 2rem;
  color: #2c3e50;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.giving-card-text {
  font-size: 1.2rem;
  color: #444444;
  line-height: 1.8;
  text-align: left;
  margin-bottom: 20px;
}

.giving-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #1976d2;
  border-radius: 50px;
  transition: background-color 0.3s;
}

.giving-button:hover {
  background-color: #125a9e;
}

.giving-image-container {
  flex: 1;
  position: relative;
}

.giving-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  filter: grayscale(50%);
  transition: filter 0.3s ease;
  border-radius: 0 0 15px 15px;
  margin-top: 20px;
}

.address-box {
  margin-top: 20px;
  padding: 15px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.address-text {
  font-size: 1rem;
  color: #333;
  margin-right: 10px;
}

.copy-button {
  color: #1976d2;
}

.copy-button:hover {
  color: #125a9e;
}

/* Hover Effects */
.giving-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.giving-card:hover .giving-image {
  filter: grayscale(0%);
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .contact-hero-title {
    font-size: 2.5rem;
  }

  .contact-hero-subtitle {
    font-size: 1.2rem;
  }

  .giving-card {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .giving-content {
    padding: 20px;
  }

  .giving-card-title {
    font-size: 1.5rem;
  }

  .giving-card-text {
    font-size: 1.1rem;
  }

  .giving-image-container {
    width: 100%;
    height: auto;
  }

  .giving-image {
    border-radius: 15px 15px 0 0;
    margin-top: 0;
  }
}
