.about-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding-bottom: 50px;
}

/* Hero Section */
.about-hero-container {
  background-image: url('/public/images/four.jpeg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-intro-container {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 50px;
  border-radius: 15px;
  max-width: 900px;
  text-align: center;
}

.intro-title {
  font-size: 3.5rem;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.intro-description {
  font-size: 4.5rem;
  line-height: 1.8;
  color: #f1f1f1;
}

/* Section Title */
.section-title {
  font-size: 4.5rem;
  font-weight: bold;
  color: #2c3e50;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 40px;
}

/* Team Grid */
.team-grid {
  margin-top: 50px;
}

/* Team Card */
.team-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.team-image-container {
  flex: 0.4;
  position: relative;
  height: 400px; /* Set the fixed height you want for all images */
}

.team-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
  display: block;
  filter: grayscale(50%);
  transition: filter 0.3s ease;
  border-radius: 15px 0 0 15px;
}

.team-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Darker overlay for better readability */
  color: #ffffff;
  padding: 15px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.team-content {
  flex: 0.6;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team-name {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffffff;
}

.team-title {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #cccccc;
}

.team-introduction {
  font-size: 1rem;
  color: #666666;
  line-height: 1.8;
  margin-top: 20px;
}

/* Hover Effects */
.team-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.team-card:hover .team-image {
  filter: grayscale(0%);
}

.team-card:hover .team-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .team-card {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .team-image {
    border-radius: 15px 15px 0 0;
  }

  .team-content {
    padding: 20px;
  }

  .intro-title {
    font-size: 2.5rem;
  }

  .intro-description {
    font-size: 1.2rem;
  }
}
