.toast-notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #323232;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    opacity: 0.9;
  }
  